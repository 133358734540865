@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
html {
  height: 100%;
}
#root, #root>div {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Varela Round', sans-serif;
}

.bubble {
  background: red;
  top: 3px;
  left: 3px;
  width: 80px;
  height: 80px;
  position: absolute;
  text-align: center;
  z-index: 5;
  border-radius: 100px;
}
.bubbleOuter {
  background: white;
  right: 20px;
  top: 50px;
  width: 86px;
  height: 86px;
  position: absolute;
  text-align: center;
  z-index: 1;
  border-radius: 100px;
}
.bubbleInner {
  background-color: transparent;
  color: white;
  font-weight: bold;
  position: relative;
  top: 20px;
  font-size: 16px;
  line-height: 20px !important;
  z-index: 20;
}